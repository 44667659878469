// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// ----- Color 顏色 -----
// scss-docs-start color-variables
// USE:scss 中直接只用$變數
// gray scale
$silver: #8e8e8e !default;
$grey: #696969 !default;
$light-grey: #e5e5e5 !default;
$gainsbor: #f5f5f5 !default;
$gainsboro: #f1f5f8 !default;
$dark-grey: #3e3a39 !default;
$sea-foam: #29afcb !default;

$black: #1e1e1e !default;
$black9: #393939 !default;
$black8: #4b4b4b !default;
$black7: #5e5e5e !default;
$black6: #727272 !default;
$black5: #868686 !default;
$black4: #9b9b9b !default;
$black3: #b0b0b0 !default;
$black2: #c7c7c7 !default;
$white1: #f9f9f9 !default;
$white: #ffffff !default;

$green: #3bdbbf !default;
$green9: #004835 !default;
$green8: #005b47 !default;
$green7: #00705a !default;
$green6: #00856d !default;
$green5: #009a81 !default;
$green4: #00b096 !default;
$green3: #11c6ab !default;
$green2: #3eddc1 !default;
$green1: #5cf4d7 !default;
$green0: #76ffee !default;

$pale: #29afcb !default;
$pale9: #00445d !default;
$pale8: #005771 !default;
$pale7: #006b85 !default;
$pale6: #007f9a !default;
$pale5: #0094af !default;
$pale4: #1da9c5 !default;
$pale3: #42bfdc !default;
$pale2: #5ed6f2 !default;
$pale1: #78ecff !default;
$pale0: #78ecff !default;

$blue: #0049e7 !default;
$blue9: #0029bd !default;
$blue8: #003ad3 !default;
$blue7: #134ceb !default;
$blue6: #445fff !default;
$blue5: #6373ff !default;
$blue4: #7f87ff !default;
$blue3: #9a9cff !default;
$blue2: #b3b1ff !default;
$blue1: #cdc7ff !default;
$blue0: #e6deff !default;
$bpale-blue: #29afcb;
$bpale-blue7: #006b85;
$bpale-blue8: #005771;

$red: #ec5760 !default;
$red9: #840016 !default;
$red8: #9d0026 !default;
$red7: #b52237 !default;
$red6: #ce3c4a !default;
$red5: #e7535c !default;
$red4: #ff6970 !default;
$red3: #ff8084 !default;
$red2: #ff9699 !default;
$red1: #ffadae !default;
$red0: #ffc4c4 !default;

// scss-docs-end color-variables

// scss-docs-start colors-map
// USE:html中class加入色票名稱 視同定義color ex: black => color:block;
// USE:html中class加入bg-色票名稱 視同定義bg-color ex: bg-block => background-color:block;
// $colors: (
//   "black": $black,
//   "grey": $grey,
//   "white": $white,
//   "silver": $silver,
//   "light-grey": $light-grey,
//   "gainsbor": $gainsbor,
//   "pearl": $pearl,
//   "aqua": $aqua,
//   "sea-foam": $sea-foam,
//   "cerulean-blue": $cerulean-blue,
//   "pale-lilac": $pale-lilac,
//   "tomato": $tomato,
//   "dark-grey": $dark-grey,
//   "crimson": $crimson,
//   "gainsboro": $gainsboro,
// );
// // scss-docs-end colors-map

// link color
$link-color: $pale;
$link-decoration: none;
$link-hover-color: $pale;
$link-hover-decoration: none;

// font system
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
) !default;
// scss-docs-end position-map

// Body

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;
$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
// scss-docs-end spacer-variables-maps

// Prefix for :root CSS variables
$variable-prefix: cc- !default;
// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// $font-family-main: "PingFang TC", "Helvetica", "Arial", "LiHei Pro", "黑體-繁", "微軟正黑體", sans-serif !default;
$font-family-main: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, sans-serif !default;

$font-size-root: 16px;
// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
// $grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;
// $gutters: $spacers !default;

// Container padding

// $container-padding-x: $grid-gutter-width * 0.5 !default;

// filter
$filter-white: invert(100%) sepia(3%) saturate(0%) hue-rotate(177deg) brightness(103%) contrast(105%);
$filter-red: invert(38%) sepia(50%) saturate(1229%) hue-rotate(316deg) brightness(106%) contrast(85%);
$filter-red2: invert(38%) sepia(50%) saturate(1229%) hue-rotate(316deg) brightness(112%) contrast(32%);
$filter-green: invert(49%) sepia(35%) saturate(5242%) hue-rotate(141deg) brightness(97%) contrast(101%);
$filter-pale: invert(51%) sepia(99%) saturate(385%) hue-rotate(143deg) brightness(96%) contrast(81%);
$filter-green4: invert(49%) sepia(83%) saturate(4419%) hue-rotate(147deg) brightness(103%) contrast(101%);
$filter-black2: invert(88%) sepia(1%) saturate(0%) hue-rotate(89deg) brightness(95%) contrast(83%);
$filter-black3: invert(74%) sepia(0%) saturate(7481%) hue-rotate(26deg) brightness(103%) contrast(81%);
$filter-black7: invert(34%) sepia(0%) saturate(33%) hue-rotate(209deg) brightness(106%) contrast(91%);
$filter-black8: invert(26%) sepia(0%) saturate(1274%) hue-rotate(202deg) brightness(97%) contrast(83%);
$filter-black9: invert(16%) sepia(1%) saturate(0%) hue-rotate(199deg) brightness(94%) contrast(79%);
$filter-heart: invert(41%) sepia(75%) saturate(716%) hue-rotate(324deg) brightness(86%) contrast(102%);
$filter-blue7: invert(32%) sepia(64%) saturate(7283%) hue-rotate(176deg) brightness(82%) contrast(106%);
$filter-white1: invert(98%) sepia(0%) saturate(7412%) hue-rotate(185deg) brightness(124%) contrast(95%);
