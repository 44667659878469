// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xxxs: 0,
  xxs: 280px,
  xs: 375px,
  sx: 414px,
  sm: 600px,
  md: 768px,
  lg: 1024px,
  lb: 1200px,
  xl: 1380px,
  xxl: 1920px,
) !default;
$xxs: map-get($grid-breakpoints, "xxs") !default;
$xs: map-get($grid-breakpoints, "xs") !default;
$sx: map-get($grid-breakpoints, "sx") !default;
$sm: map-get($grid-breakpoints, "sm") !default;
$md: map-get($grid-breakpoints, "md") !default;
$lg: map-get($grid-breakpoints, "lg") !default;
$lb: map-get($grid-breakpoints, "lb") !default;
$xl: map-get($grid-breakpoints, "xl") !default;
$xxl: map-get($grid-breakpoints, "xxl") !default;
// scss-docs-end grid-breakpoints

// Ex:
// @include rwd(0, $xs - 1px) {
// }
// @include rwd($xs, $sm - 1px) {
// }
// @include rwd($sm, $md - 1px) {
// }
// @include rwd($md, $lg - 1px) {
// }
// @include rwd($lg, $xl - 1px) {
// }
// @include rwd(null, null, $xl) {
// }

// scss-docs-start container-max-widths
$container-max-widths: (
  xxs: 250px,
  xs: 344px,
  sx: 384px,
  sm: 522px,
  md: 708px,
  lg: 1000px,
  lb: 1150px,
  xl: 1370px,
  xxl: 1723px
) !default;
// scss-docs-end container-max-widths
