@import './system.scss';

// 登入起始頁面
#backstage-login {
    width: 100vw;
    height: 100vh;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    @include rwd($xxl - 1px) {
        width: 100%;
        min-width: 1380px;
        height: 100%;
    }
    // 背景圖
    .bg-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .content-wrapper {
        width: 440px;
        display: flex;
        flex-direction: column;
        align-items: left;
        // logo圖
        .logo-box {
            @include whset(415px, 73px);
        }
        // 登入框
        .login-block {
            width: 100%;
            height: 400px;
            background: $white;
            box-shadow: 0px 0px 10px rgba(0, 132, 160, 0.45);
            position: relative;
            z-index: 1;
            border-radius: 22px;
            margin: 25px auto 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > .img-box {
                @include whset(153px, 42px);
                margin-bottom: 22px;
            }
            h6 {
                color: $black3;
                margin-bottom: 46px;
            }
            .twitter-login {
                width: 342px;
                height: 50px;
                margin: 23px 0 85px;
                border: 1px solid rgba($black2, 0.4);
                border-radius: 12px;
                backdrop-filter: blur(10px);
                transition: 0.2s ease-out;
                &:hover {
                    background: $pale;
                    border: 1px solid $pale;
                    span {
                        color: $white;
                    }
                    .img-box {
                        .t1 {
                            opacity: 0;
                        }
                        .t2 {
                            opacity: 1;
                        }
                    }
                }
                .img-box {
                    @include whset(28px, 28px);
                    margin-right: 15px;
                    .t1 {
                        transition: 0.2s ease-out;
                        opacity: 1;
                    }
                    .t2 {
                        transition: 0.2s ease-out;
                        opacity: 0;
                    }
                }
                span {
                    transition: 0.2s ease-out;
                    color: $black;
                }
            }
            .return-box {
                width: 342px;
            }
        }
        h4 {
            color: $white;
            position: relative;
            z-index: 1;
            margin-left: 30px;
        }
    }
}
