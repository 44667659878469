@import './system.scss';

// 確認小彈窗
.confirm-box {
    width: 326px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 161px;
    background: $white;
    box-shadow: 0px 0px 20px rgba(190, 190, 190, 0.25);
    border-radius: 12px;
    position: fixed;
    opacity: 0;
    z-index: -1;
    top: 50%;
    left: 50%;
    margin-bottom: 0 !important;
    transform: translateX(-50%) translateY(-50%);
    max-height: 100%;
    overflow-y: scroll;
    .p1 {
        margin-bottom: 29px;
        color: $black !important;
    }
    &.act {
        z-index: 15;
        opacity: 1;
        span {
            &:first-of-type {
                border: 1px solid rgba($black2, 0.4);
            }
            &:last-of-type {
                background: $red;
                color: $white;
                &:hover {
                    background: $red7;
                }
            }
        }
    }
    &.act2 {
        z-index: 15;
        opacity: 1;
        span {
            &:first-of-type {
                border: 1px solid $pale;
                color: $pale;
                &:hover {
                    background: $pale;
                    color: $white;
                }
            }
            &:last-of-type {
                background: $pale;
                color: $white;
                &:hover {
                    background: $pale5;
                }
            }
        }
    }
    &.long {
        height: 180px;
        .p1 {
            width: 280px;
            text-align: center;
            line-height: 170%;
        }
        .bts {
            span {
                width: 109px;
            }
        }
    }
    .bts {
        margin: 0 !important;
        span {
            width: 87px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 41px;
            border-radius: 6px;
            transition: 0.3s ease-out;
            &:first-of-type {
                margin-right: 20px;
            }
        }
    }
    &.notitle {
        width: 875px;
        padding: 40px 60px 0;
        height: unset;
        justify-content: unset;
        > div {
            width: 100%;
        }
        > p {
            height: 100%;
            width: 100%;
        }
        .bts {
            width: 100%;
            justify-content: center;
        }
        // 中獎名單
        #winners {
            .top-box {
                width: 100%;
                margin-bottom: 30px;
                > div {
                    width: 100%;
                    h4 {
                        flex-grow: 1;
                        p {
                            @include wordrow(1);
                            margin-bottom: 7px;
                        }
                    }
                    div {
                        width: 286px;
                        p {
                            color: $black3;
                            flex-basis: 100%;
                            &:last-of-type {
                                margin: 25px 0;
                            }
                        }
                        button {
                            width: 100%;
                            background: $pale;
                            border-radius: 6px;
                            padding: 6.5px 0;
                            color: white;
                        }
                    }
                }
            }
            .bot-box {
                .winner-info {
                    padding: 17px 23px;
                    border-radius: 6px;
                    background: $white1;
                    margin-bottom: 30px;
                    &:last-of-type {
                        margin: 0;
                    }
                    div {
                        display: flex !important;
                        align-items: center;
                        color: $black9;
                        background: $white;
                        padding: 10px 17px;
                        border-radius: 6px;
                        margin-bottom: 8px;
                        p {
                            width: 126px;
                        }
                        span {
                            color: $black9;
                            border: none;
                            @include sprfs('bd4n');
                            background: none;
                        }
                    }
                }
            }
        }
    }
}

// 變更修正彈窗
.modify-wrapper {
    position: fixed;
    z-index: -1;
    opacity: 0;
    transition: 0.2s ease-out;
    width: 100%;
    height: 100vh;
    background: rgba($black, 0.3);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .shadow {
        opacity: 0;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
    &.act {
        opacity: 1;
        z-index: 20;
    }
    > div:first-of-type {
        width: 1290px;
        max-height: 100%;
        overflow-y: scroll;
        background: $white;
        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
        border-radius: 12px;
        padding: 87px 0 97px;
        transition: 0.5s linear;
        .modify-box {
            margin: 0 auto;
            width: 750px;
            overflow: hidden;
            h1 {
                text-align: center;
                margin-bottom: 40px;
            }
            > div {
                border: 1px solid $light-grey;
                border-radius: 6px;
                padding: 23px 20px 0;
                margin-bottom: 12px;
                transition: 1s linear;
                max-height: 65px;
                cursor: pointer;
                &.act {
                    max-height: 3000px;
                    .inner {
                        z-index: 1;
                        opacity: 1;
                        max-height: 3000px;
                    }
                }
                > h2 {
                    text-align: center;
                    margin-bottom: 23px;
                    color: $black9;
                }
                .inner {
                    position: relative;
                    z-index: -1;
                    max-height: 0;
                    opacity: 0;
                    transition: 0.2s linear;
                }
            }
            // 抽獎時間
            .lot-time {
                .react-datepicker-time__header {
                    color: transparent;
                    position: relative;
                    &::after {
                        position: absolute;
                        content: '時間';
                        color: black;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .react-datepicker__triangle::before,
                .react-datepicker__triangle::after {
                    left: -15px !important;
                }
                .inner {
                    p {
                        margin-bottom: 7px;
                        display: inline-block;
                        color: $black9;
                        position: relative;
                        &.must {
                            &::after {
                                content: '＊';
                                position: absolute;
                                color: $red;
                                right: -15px;
                                top: -2px;
                                transform: scale(0.7);
                            }
                        }
                    }
                    input {
                        padding: 15px 20px;
                        width: 100%;
                        border-radius: 4px;
                        margin-bottom: 12px;
                        border: 1px solid $light-grey;
                        color: $black;
                        position: relative;
                        &::placeholder {
                            color: $black3;
                        }
                        &:last-of-type {
                            margin-bottom: 29px;
                        }
                    }
                    input[type='datetime-local']::-webkit-calendar-picker-indicator {
                        position: absolute;
                        cursor: pointer;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url('../../public/images/marketBackstage/calendar.svg');
                        right: 0;
                        height: 22px;
                        width: 22px;
                        padding: 12px 10px 12px 100%;
                    }
                }
            }

            //抽獎名額
            .lot-number {
                .inner {
                    margin: 0 auto;
                    text-align: center;
                }
                h2 {
                    span {
                        position: relative;

                        &::after {
                            content: '＊';
                            position: absolute;
                            color: $red;
                            right: -15px;
                            top: -2px;
                            transform: scale(0.7);
                        }
                    }
                }
                input {
                    width: 590px;
                    border: 1px solid $light-grey;
                    border-radius: 4px;
                    padding: 15px 20px;
                    margin: 0 auto 30px;
                    color: $black;
                    &::placeholder {
                        color: $black3;
                    }
                }
            }

            // 抽獎條件
            .lot-condition {
                > h2 {
                    margin-bottom: 23px;
                }
                .inner {
                    padding: 0 12px;
                    > div {
                        padding: 40px 0;
                        &:nth-of-type(3),
                        &:nth-of-type(4),
                        &:nth-of-type(5),
                        &:nth-of-type(6),
                        &:nth-of-type(7) {
                            button {
                                width: 327px;
                            }
                        }
                        h2 {
                            color: $dark-grey;
                            display: flex;
                            align-items: center;
                            img {
                                margin-right: 16px;
                                width: 40px;
                                height: 40px;
                                object-fit: contain;
                                object-position: center;
                            }
                        }
                        > p {
                            margin: 16px 0 30px;
                            color: $black5;
                        }
                        > div {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            button {
                                width: 211px;
                                border: 1px solid $pale;
                                border-radius: 6px;
                                color: $pale;
                                height: 41px;
                                transition: 0.2s ease-out;
                                &.act {
                                    background: $pale;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }

            // 項目基本資訊
            .lot-info {
                .inner {
                    width: 472px;
                    text-align: left;
                    margin: 0 auto;
                    .price-box {
                        padding: 6px 0;
                        display: flex;
                        align-items: center;
                        margin-bottom: 7px;
                        justify-content: flex-start;
                        button {
                            height: 41px;
                            margin-right: 10px;
                            border-radius: 6px;
                            width: 114px;
                            background: $white;
                            color: $pale;
                            border: 1px solid $pale;
                            &.act {
                                background: $pale;
                                color: $white;
                            }
                        }
                    }
                    h6 {
                        margin-bottom: 7px;
                        color: $dark-grey;
                        position: relative;
                        display: inline-block;
                        &.must {
                            &::after {
                                content: '＊';
                                position: absolute;
                                color: $red;
                                right: -15px;
                                top: -2px;
                                transform: scale(0.7);
                            }
                        }
                    }
                    p {
                        border: 1px solid $light-grey;
                        cursor: pointer;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        padding: 15px 20px;
                        position: relative;
                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                        }
                    }
                    input {
                        position: relative;
                    }
                    input[type='date']::-webkit-calendar-picker-indicator {
                        position: absolute;
                        cursor: pointer;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url('../../public/images/marketBackstage/calendar.svg');
                        right: 0;
                        height: 22px;
                        width: 22px;
                        padding: 12px 10px 12px 100%;
                    }
                    input[type='date'] {
                        color: transparent;
                        &::before {
                            color: $black3;
                            content: attr(placeholder) !important;
                            margin-right: 0.5em;
                            width: 100%;
                        }
                        &.act {
                            color: unset;
                            &::before {
                                width: 0;
                                content: '' !important;
                            }
                        }
                    }
                    h5 {
                        color: $black5;
                        margin: 16px 0 30px;
                    }
                    input {
                        padding: 15px 20px;
                        width: 100%;
                        cursor: pointer;
                        border: 1px solid $light-grey;
                        border-radius: 4px;
                        margin-bottom: 30px;
                        color: $black;
                        &:last-of-type {
                            margin-bottom: 48px;
                        }
                        &::placeholder {
                            color: $black3;
                        }
                    }

                    // 領獎方式
                    .accept-box {
                        margin-bottom: 32px;
                        button {
                            width: 230px;
                            color: $pale;
                            border: 1px solid $pale;
                            @include sprfs('btn2');
                            flex-grow: 1;
                            height: 40px;
                            transition: 0.2s ease-out;
                            border-radius: 6px;
                            &.act {
                                background: $pale;
                                color: $white;
                            }
                            &:nth-of-type(2) {
                                margin: 0 25px;
                            }
                        }
                    }
                }
            }

            // 其他內容
            .lot-others {
                h2 {
                    span {
                        position: relative;

                        &::after {
                            content: '＊';
                            position: absolute;
                            color: $red;
                            right: -15px;
                            top: -2px;
                            transform: scale(0.7);
                        }
                    }
                }
                textarea {
                    padding: 25px 21px;
                    @include sprfs('bd4-n');
                    border: 1px solid rgba($black2, 0.4);
                    border-radius: 12px;
                    color: $black;
                    width: 100%;
                    resize: none;
                    min-height: 306px;
                    margin-bottom: 35px;
                    &::placeholder {
                        color: $black3;
                    }
                }
            }

            .btns-box {
                width: 100%;
                border: none;
                margin-top: 40px;
                display: flex;
                padding: 0;
                justify-content: space-between;
                button {
                    width: 328px;
                    height: 40px;
                    border: 1px solid rgba($black2, 0.4);
                    border-radius: 6px;
                    color: $black;
                    transition: 0.3s ease-out;
                    &:first-of-type {
                        &:hover {
                            background: $pale;
                            color: $white;
                        }
                    }
                    &:last-of-type {
                        background: $pale;
                        color: $white;
                        border: none;
                        &:hover {
                            background: $pale5;
                        }
                    }
                }
            }
        }
    }
    .set-box {
        background: $white;
        width: 100%;
        height: 100%;

        > h1 {
            text-align: center;
            margin-bottom: 58px;
        }
        .inner {
            margin: 0 auto;
            > div {
                transition: 0.2s ease-out;
                border-radius: 12px;
                padding: 40px 32px;
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7) {
                    button {
                        width: 327px;
                    }
                }
                .more-box {
                    transition: 0.2s ease-out;
                    flex-wrap: wrap;
                    .top,
                    .bottom,
                    .raffle {
                        position: relative;
                        opacity: 0;
                        z-index: -1;
                        max-height: 0;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                }
                h2 {
                    color: $dark-grey;
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 16px;
                        width: 40px;
                        height: 40px;
                        object-fit: contain;
                        object-position: center;
                    }
                }
                > p {
                    margin: 16px 0 30px;
                    color: $black5;
                }
                > div {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    button {
                        width: 211px;
                        border: 1px solid $pale;
                        border-radius: 6px;
                        color: $pale;
                        height: 41px;
                        transition: 0.2s ease-out;
                        &.act {
                            background: $pale;
                            color: $white;
                        }
                    }
                }
            }
            .btns {
                margin-top: 32px;
                border: none;
                padding: 0;
                margin-bottom: 30px;
                button {
                    width: 306px;
                    height: 41px;
                    transition: 0.2s ease-out;
                    border-radius: 6px;
                    &.back {
                        background: $white;
                        border: 1px solid $pale;
                        color: $pale;
                        &:hover {
                            color: $white;
                            background: $pale;
                        }
                    }
                    &.save {
                        background: $blue5;
                        color: $white;
                    }
                }
            }
        }
        // discord
        .dc-box {
            transition: 0.2s ease-out;
            &.act {
                .more-box {
                    .top {
                        opacity: 1;
                        z-index: 1;
                        max-height: unset;
                        padding-top: 30px;
                    }
                }
            }
            &.act2 {
                .more-box {
                    .top,
                    .bottom,
                    .raffle {
                        opacity: 1;
                        z-index: 1;
                        max-height: unset;
                        padding-top: 30px;
                    }
                    .bottom {
                        margin-top: 30px;
                        border-top: 1px dashed rgba($black2, 0.4);
                    }
                    // 抽獎券加成
                    .raffle {
                        margin-top: 30px;
                        padding-top: 30px;
                        border-top: 1px dashed rgba($black2, 0.4);
                        h5 {
                            color: $dark-grey;
                            margin-bottom: 10px;
                        }
                        h6 {
                            color: $black3;
                            margin-bottom: 30px;
                        }
                        > div {
                            padding: 27px 14px;
                            border-radius: 6px;
                            border: 1px solid rgba($black2, 0.4);
                        }
                    }
                }
            }
            .more-box {
                transition: 0.2s ease-out;
                display: block;
                .top,
                .bottom,
                .raffle {
                    padding-top: 0;
                    transition: 0.2s ease-out;
                    position: relative;
                    opacity: 0;
                    z-index: -1;
                    max-height: 0;
                    h5 {
                        margin-bottom: 30px;
                        color: $dark-grey;
                    }
                    p {
                        margin-bottom: 10px;
                        color: $dark-grey;
                        img {
                            width: 20px;
                            height: 20px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }
                    input {
                        margin-bottom: 30px;
                        width: 100%;
                        border: 1px solid rgba($black2, 0.4);
                        border-radius: 4px;
                        padding: 15px 20px;
                        color: $black;
                        @include sprfs('bd4-n');
                        &:last-of-type {
                            margin: 0;
                        }
                        &::placeholder {
                            color: $black3;
                        }
                    }
                }
                .raffle {
                    > div {
                        margin-bottom: 30px;
                        .first {
                            margin-bottom: 30px;
                            div {
                                width: 49%;
                            }
                        }
                    }
                    // 新增role按鈕
                    button {
                        background: $pale;
                        color: $white !important;
                    }
                }
            }
        }
        .twitter-box {
            .more-box {
                .top {
                    > div {
                        height: unset !important;
                        align-items: stretch !important;
                    }
                }
            }
        }
        // twitter
        .twitter-box,
        .instagram-box,
        .facebook-box {
            &.act {
                .more-box {
                    .top {
                        padding-top: 30px;
                        opacity: 1 !important;
                        z-index: 1 !important;
                        max-height: unset !important;
                    }
                }
            }
            &.act2 {
                .bottom,
                .top {
                    padding-top: 30px;
                    opacity: 1 !important;
                    z-index: 1 !important;
                    max-height: unset !important;
                }
            }
            .more-box {
                .top,
                .bottom {
                    p {
                        margin-bottom: 10px;
                    }
                }
                .top {
                    > div {
                        width: 100%;
                        border-radius: 4px;
                        overflow: hidden;
                        height: 46px;
                        border: 1px solid rgba($black2, 0.4);
                        span {
                            margin: 0 0 2px 10px;
                            cursor: pointer;
                            border: 1px solid rgba($black2, 0.4);
                            color: $black8;
                            height: 27px;
                            padding: 4px 30px 5px 13px;
                            border-radius: 4px;
                            position: relative;
                            &::after {
                                content: '྾';
                                font-weight: 500;
                                font-size: 12px;
                                position: absolute;
                                right: 10px;
                                top: 4px;
                            }
                        }
                        input {
                            padding-left: 15px;
                            color: $black;
                            flex-grow: 1;
                            padding-right: 15px;
                            &.act {
                                width: 0;
                            }
                            &::placeholder {
                                color: $black3;
                            }
                        }
                        > div {
                            flex-wrap: wrap;
                            padding: 7px 0;
                            max-width: 470px;
                        }
                        .img-box {
                            padding: 0;
                            background: rgba(199, 199, 199, 0.4);
                            display: flex;
                            align-items: center;
                            width: 50px;
                            flex-shrink: 0;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
                .bottom {
                    display: flex;
                    .left {
                        width: 270px;
                        margin-right: 12px;
                        input {
                            padding: 0 20px;
                            height: 46px;
                            width: 100%;
                            color: $black;
                            border: 1px solid rgba($black2, 0.4);
                            border-radius: 4px;
                            @include sprfs('bd4-n');
                            &::placeholder {
                                color: $black3;
                            }
                        }
                    }
                    .right {
                        flex-grow: 1;
                        button {
                            width: 127px;
                            height: 46px;
                            transition: 0.2s ease-out;
                            border: 1px solid $pale;
                            color: $pale;
                            border-radius: 6px;
                            &.act {
                                background: $pale;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        // 錢包
        .wallet-box {
            display: block;
            &.act {
                .more-box {
                    .top {
                        padding-top: 30px;
                        opacity: 1;
                        z-index: 1;
                        max-height: unset;
                    }
                }
            }
            .more-box {
                .top {
                    width: 100%;
                    p {
                        color: $dark-grey;
                        margin-bottom: 7px;
                    }
                    input {
                        padding: 15px 20px;
                        @include sprfs('bd4-n');
                        width: 100%;
                        border: 1px solid rgba($black2, 0.4);
                        color: $black;
                        border-radius: 4px;
                        &::placeholder {
                            color: $black3;
                        }
                    }
                }
            }
        }

        // NFT
        .NFT-box {
            &.act {
                .more-box {
                    .top {
                        padding-top: 30px;
                        max-height: unset;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
            .top {
                width: 100%;
                p {
                    color: $dark-grey;
                    margin-bottom: 7px;
                }
                input {
                    padding: 15px 20px;
                    @include sprfs('bd4-n');
                    width: 100%;
                    border: 1px solid rgba($black2, 0.4);
                    color: $black;
                    border-radius: 4px;
                    margin-bottom: 22px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &::placeholder {
                        color: $black3;
                    }
                }
            }
        }

        // 問題
        .question-box {
            &.act {
                .more-box {
                    .top {
                        padding-top: 30px;
                        max-height: unset;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
            .top {
                width: 100%;
                p {
                    color: $dark-grey;
                    margin-bottom: 7px;
                }
                input {
                    padding: 15px 20px;
                    @include sprfs('bd4-n');
                    width: 100%;
                    border: 1px solid rgba($black2, 0.4);
                    color: $black;
                    border-radius: 4px;
                    margin-bottom: 22px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &::placeholder {
                        color: $black3;
                    }
                }
            }
        }

        // 閱讀文章
        .read-box {
            &.act {
                .more-box {
                    .top {
                        padding-top: 30px;
                        max-height: unset;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
            .more-box {
                flex-grow: 1;
            }
            .top {
                p {
                    color: $dark-grey;
                    margin-bottom: 7px;
                }
                .article {
                    position: relative;
                    img {
                        position: absolute;
                        top: 15px;
                        z-index: 1;
                        right: 15px;
                        transition: 0.3s linear;
                        &.act {
                            transform: rotate(180deg);
                        }
                    }
                    input {
                        display: block;
                        padding: 15px 45px 15px 20px;
                        @include sprfs('bd4-n');
                        width: 100%;
                        border: 1px solid rgba($black2, 0.4);
                        color: $black;
                        border-radius: 4px;
                        position: relative;
                        &::placeholder {
                            color: $black3;
                        }
                    }
                    ul {
                        border: 1px solid rgba($black2, 0.4);
                        border-radius: 4px;
                        background: white;
                        width: 100%;
                        max-height: 0;
                        overflow-y: scroll;
                        transition: 0.3s linear;
                        opacity: 0;
                        position: absolute;
                        top: 48.5px;
                        &.act {
                            opacity: 1;
                            max-height: 144px;
                        }
                        li {
                            cursor: pointer;
                            @include sprfs('bd4-n');
                            color: $black3;
                            padding: 15px 20px;
                            border-bottom: 1px solid rgba($black2, 0.4);
                            width: 100%;
                            transition: 0.2s ease-out;
                            &:hover {
                                background-color: rgba($black2, 0.4);
                            }
                            &:last-of-type {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 填email彈窗
.apply-wrapper {
    transition: 0.2s ease-out;
    position: fixed;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
    border-radius: 12px;
    width: 554px !important;
    height: 235px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .email {
        width: 503px;
        margin: 45px auto 0;
        p {
            color: $dark-grey;
            margin-bottom: 10px;
            span {
                color: $red;
                margin-left: 7px;
                display: inline-block;
                transform: scale(0.95);
            }
        }
        input {
            border: 1px solid rgba($black2, 0.4);
            width: 100%;
            border-radius: 4px;
            padding: 15px 18px;
            color: $black;
            margin-bottom: 32px;
            &::placeholder {
                color: $black3;
            }
        }
        .btns {
            display: flex;
            justify-content: space-between;
            button {
                width: 225px;
                height: 40px;
                border-radius: 6px;
                border: 1px solid rgba($black2, 0.4);
                &:last-of-type {
                    background: $black3;
                    cursor: not-allowed;
                    border: none;
                    color: $white;
                    &.act {
                        cursor: pointer;
                        background: $pale;
                    }
                }
            }
        }
    }
    .send {
        text-align: center;
        h4 {
            margin: 35px auto 24px;
            text-align: center;
        }
        p {
            color: $black5;
            width: 360px;
            margin: 0 auto 37px;
        }
        button {
            width: 225px;
            height: 40px;
            border: 1px solid rgba($black2, 0.4);
            border-radius: 6px;
            color: $black9;
        }
    }
}

//防呆彈窗
.post-pop {
    transition: 0.2s ease-out;
    position: fixed;
    opacity: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($black, 0.3);
    &.act {
        opacity: 1;
        z-index: 10;
    }
    > div {
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
        border-radius: 12px;
        width: 554px !important;
        height: 381px;
        h4 {
            margin-top: 42px;
            margin-bottom: 30px;
            color: $dark-grey;
            text-align: center;
        }
        p {
            margin: 0 auto 38px;
            width: 414px;
            color: rgba($black, 0.8);
            span {
                color: rgba($red, 0.8);
            }
        }
        .btns {
            width: 472px;
            margin: 0 auto;
            button {
                width: 225px;
                height: 41px;
                border: 1px solid rgba($black2, 0.4);
                border-radius: 6px;
                color: $black9;
                &:last-of-type {
                    border: none;
                    color: $white;
                    background: $blue5;
                    transition: 0.2s ease-out;
                    &:hover {
                        background: $blue7;
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// 成功小動畫
#success-box {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 276px;
    height: 82px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(190, 190, 190, 0.25);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.2s linear;
    div {
        @include whset(56px, 56px);
        margin-right: 20px;
    }
}
