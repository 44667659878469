// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.
// @mixin isSafari {
//     -webkit-text-stroke: 0.5px;
//     @media not all and (min-resolution:.001dpcm){
//         -webkit-text-stroke: unset;
//     }
// }

@mixin h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 120%;
    font-size: $h1-font-size;
    // @include isSafari();
}

@mixin h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 120%;
    font-size: $h2-font-size;
    // @include isSafari();
}

@mixin h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 120%;
    font-size: $h3-font-size;
    // @include isSafari();
}

@mixin h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 120%;
    font-size: $h4-font-size;
    // @include isSafari();
}

@mixin h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 120%;
    font-size: $h5-font-size;
    // @include isSafari();
}

@mixin h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 120%;
    font-size: $h6-font-size;
    // @include isSafari();
}

// button ------
@mixin btn1 {
    line-height: 120%;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 1.25px;
}

@mixin btn2 {
    line-height: 120%;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    letter-spacing: 1.25px;
}

@mixin btn3-b {
    line-height: 120%;
    font-size: 1rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.25px;
    // @include isSafari();
}

@mixin btn4-b {
    font-style: normal;
    line-height: 120%;
    font-size: 1.25rem;
    font-weight: 700;
    // @include isSafari();
}

// body ------
@mixin bd1-n {
    line-height: 150%;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.25px;
}

@mixin bd1-b {
    line-height: 120%;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.25px;
    // @include isSafari();
}

@mixin bd2-n {
    line-height: 180%;
    font-weight: 400;
    font-size: 1.125rem;
    letter-spacing: 0.25px;
}

@mixin bd2-b {
    line-height: 180%;
    font-weight: 700;
    font-size: 1.125rem;
    letter-spacing: 0.25px;
    // @include isSafari();
}

@mixin bd3-n {
    line-height: 120%;
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 0.25px;
}

@mixin bd3-b {
    line-height: 120%;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 23px;
    letter-spacing: 0.25px;
    // @include isSafari();
}

@mixin bd4-n {
    line-height: 120%;
    font-weight: 300;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
}

@mixin bd4-b {
    line-height: 120%;
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
}

@mixin bd5-n {
    line-height: 180%;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.5px;
}

@mixin bd5-b {
    line-height: 180%;
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    // @include isSafari();
}

@mixin bd6-n {
    line-height: 180%;
    font-weight: 400;
    font-size: 2.25rem;
    letter-spacing: 0.5px;
}

@mixin bd6-b {
    line-height: 180%;
    font-weight: 700;
    font-size: 2.25rem;
    letter-spacing: 0.5px;
    // @include isSafari();
}
