@keyframes pulse {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.15);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes bubble {
    0%{
        opacity: 0;
        transform: translateY(0);
    }
    30%{
        opacity: 1;
    }
    80%{
        opacity: 1;
    }
    100%{
        opacity: 1;
        transform: translateY(-20px);
    }
}

@keyframes moveIn {
    0%{
        opacity: 0;
        transform: translateY(100%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes drip {
    0%{
        opacity: 0;
        transform: translateY(0) scale(.9);
    }
    30%{
        opacity: 1;
        transform: translateY(-10px) scale(1);
    }
    100%{
        transform: translateY(-15px) scale(1);
    }
}

@mixin bubbleAnimate {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -33px;
    animation: bubble 1s ease-in  both;
    z-index: 1;
    > div{
        @include whset(33px,33px);
        margin: 0 auto;
    }
}

