@import './system.scss';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

// 後台頁面
#home {
    width: 100vw;
    height: 100vh;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 775px;
    background: $white1;

    @include rwd($xxl - 1px) {
        min-width: 1440px;
    }

    .main {
        width: 100%;
        height: 100%;
        display: flex;

        aside {
            width: 400px;
            height: 100%;
            padding: 35px 19px 0;
            background: #ffffff;
            box-shadow: 0px 0px 10px rgba(183, 183, 183, 0.25);

            @include rwd($xxl - 1px) {
                width: 275px;
            }

            > .inner {
                position: sticky;
                top: 0;

                .img-box {
                    @include whset(167px, 38px);
                    margin-bottom: 95px;
                }

                // 頭像
                .thumbnail {
                    background-color: white;
                    margin-bottom: 10px;
                    overflow: hidden;

                    img {
                        border: 1px solid hsla(0, 0%, 78%, 0.4);
                        border-radius: 50%;
                        height: 70px;
                        object-fit: cover;
                        object-position: center;
                        padding: 4px;
                        width: 70px;
                    }

                    // &::before {
                    //     content: '';
                    //     position: absolute;
                    //     width: 80px;
                    //     height: 80px;
                    //     border: 1px solid rgba($black2, 0.4);
                    //     background-color: white;
                    //     border-radius: 50%;
                    //     left: 50%;
                    //     top: 50%;
                    //     transform: translateX(-50%) translateY(-50%);
                    // }
                }

                h6 {
                    margin-bottom: 20px;
                }

                .log-out {
                    transition: 0.2s ease-out;
                    width: 72px;
                    height: 31px;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 23px;

                    &:hover {
                        background: $pale;

                        span {
                            color: $white;
                        }

                        div {
                            filter: unset;
                        }
                    }

                    div {
                        filter: $filter-pale;
                        @include whset(16px, 16px);
                        margin-right: 4px;
                    }

                    span {
                        color: $black3;
                    }
                }

                button {
                    width: 238px;
                    height: 41px;
                    border-radius: 6px;
                    color: $white;
                    transition: 0.2s ease-out;
                }

                .est-btn {
                    background: $pale;
                    margin-bottom: 10px;
                    color: $white;

                    &:hover {
                        background: $pale5;
                    }
                }

                .list-bnt {
                    color: $black;
                    border: 1px solid rgba($black2, 0.4);

                    &:hover {
                        border: 1px solid $blue5;
                        background: $blue5;
                        color: $white;
                    }
                }

                > p {
                    color: rgba($black8, 0.8);
                    margin-top: 240px;
                }
            }
        }

        .main-content {
            flex-grow: 1;
            height: 100%;
            padding: 30px 26px;
            overflow-y: scroll;
            width: 1105px;

            > div {
                height: 100%;
                overflow-y: scroll;
            }

            // 防呆流程
            #procedure2 {
                width: 100%;
                margin-bottom: 11px;
                background: $white;
                height: 104px;
                padding: 36px 80px 25px;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;

                div {
                    flex-grow: 1;
                    position: relative;
                    padding-top: 26px;
                    border-top: 1px solid $black2;

                    &::after {
                        content: '';
                        position: absolute;
                        top: -6px;
                        left: 0;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background: $black2;
                    }

                    p {
                        color: rgba($black4, 0.8);
                    }

                    &:last-of-type {
                        border: none;
                    }
                }

                .p1,
                .p2,
                .p3,
                .p4 {
                    border-top-color: $pale;
                }

                .p1,
                .p2,
                .p3,
                .p4,
                .p5 {
                    &::after {
                        background: $pale;
                        box-shadow: 0px 0px 10px #46c5d7;
                    }

                    p {
                        color: rgba($black, 0.8);
                    }
                }
            }

            // menu tab
            .menu-box {
                border-bottom: 1px solid rgba($black2, 0.4);
                position: sticky;
                z-index: 10;
                background: #f9f9f9;
                top: 0;

                &::after {
                    position: absolute;
                    width: 100%;
                    height: 200%;
                    top: -50px;
                    z-index: -1;
                    left: 0;
                    content: '';
                    background: #f9f9f9;
                }

                li {
                    cursor: pointer;
                    transition: 0.2s ease-out;
                    padding: 7px 10px 15px;
                    margin-right: 10px;
                    border-bottom: 3px solid transparent;
                    color: $black4;

                    &:hover {
                        color: $black;
                    }

                    &.act {
                        color: $pale;
                        border-bottom: 3px solid $pale;
                    }
                }
            }

            // 1.項目列表
            .list-box {
                height: unset;
                padding-top: 25px;

                .list-wrapper {
                    @include rwd($xxl - 1px) {
                        width: 1110px;
                    }

                    // 一個項目
                    .single-item {
                        width: 355px;
                        margin-right: 10px;
                        height: 535px;
                        background: $white;
                        border-radius: 22px;
                        padding: 19px 22px;
                        margin-bottom: 36px;
                        cursor: pointer;

                        @include rwd($xxl - 1px) {
                            margin-right: 22px;

                            &:nth-of-type(3n) {
                                margin-right: 0;
                            }
                        }

                        &:hover {
                            .img-box {
                                img {
                                    transform: scale(1.2);
                                }

                                p::after {
                                    opacity: 0;
                                }

                                p::before {
                                    opacity: 1;
                                }
                            }
                        }

                        // 項目圖
                        .img-box {
                            @include whset(311px, 311px);
                            border-radius: 12px;
                            overflow: hidden;

                            img {
                                object-fit: cover;
                                object-position: center;
                            }

                            // 分類
                            p {
                                padding: 5px 10px;
                                color: $white;
                                border-radius: 6px;
                                position: absolute;
                                z-index: 2;
                                left: 11px;
                                top: 13px;
                                backdrop-filter: blur(10px);
                                overflow: hidden;

                                &::after {
                                    left: 0;
                                    top: 0;
                                    transition: 0.2s ease-out;
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    z-index: -1;
                                    opacity: 1;
                                }

                                &::before {
                                    transition: 0.2s ease-out;
                                    left: 0;
                                    top: 0;
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    z-index: -2;
                                    opacity: 0;
                                }

                                &.type-ing {
                                    &::before {
                                        background: linear-gradient(
                                            91.79deg,
                                            rgba(44, 41, 203, 0.7) 3.79%,
                                            rgba(14, 162, 194, 0.7) 103.71%
                                        );
                                    }

                                    &::after {
                                        background: linear-gradient(
                                            91.79deg,
                                            rgba(44, 41, 203, 0.3) 3.79%,
                                            rgba(14, 162, 194, 0.3) 103.71%
                                        );
                                    }
                                }

                                &.type-not {
                                    &::before {
                                        background: rgba(12, 12, 12, 1);
                                    }

                                    &::after {
                                        background: rgba(12, 12, 12, 0.3);
                                    }
                                }

                                &.type-end {
                                    &::before {
                                        background: linear-gradient(
                                            91.79deg,
                                            rgba(93, 41, 203, 0.7) 3.79%,
                                            rgba(194, 14, 14, 0.7) 103.71%
                                        );
                                    }

                                    &::after {
                                        background: linear-gradient(
                                            91.79deg,
                                            rgba(93, 41, 203, 0.3) 3.79%,
                                            rgba(194, 14, 14, 0.3) 103.71%
                                        );
                                    }
                                }
                            }

                            img {
                                z-index: 1;
                                transition: 0.2s ease-out;
                            }
                        }

                        // 項目名稱
                        .name {
                            padding: 0 2px;
                            margin: 17px 0;
                            width: 100%;
                            @include wordrow(1);
                        }

                        > p {
                            color: $black3;
                            margin-bottom: 17px;

                            span {
                                margin-bottom: 6px;
                                display: inline-block;
                                width: 100%;
                            }
                        }

                        // 按鈕框
                        .btn-box {
                            button {
                                color: $white;
                                height: 41px;
                                border-radius: 6px;
                                transition: 0.2s ease-out;
                            }

                            .apply {
                                margin-right: 7px;
                                background: $black8;
                                width: 87px;

                                &:hover {
                                    background: $black9;
                                }
                            }

                            .delete {
                                background: $red;

                                &:hover {
                                    background: $red7;
                                }
                            }

                            a {
                                flex-grow: 1;
                            }

                            .check {
                                width: 100%;
                                background: $pale;

                                &:hover {
                                    background: $pale6;
                                }
                            }
                            .list {
                                width: 100%;
                                background: $blue5;
                                margin-right: 7px;
                                &:hover {
                                    background: $blue6;
                                }
                            }
                        }
                    }
                }

                // 空值時
                .empty {
                    margin-top: 94px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    div {
                        @include whset(487px, 348px);
                    }

                    p {
                        margin-top: 30px;
                        color: $black9;
                    }
                }
            }

            // 2.抽獎時間填寫
            .lottery-time {
                padding: 66px 0;
                background: $white;
                overflow-y: scroll;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;
                width: 100%;
                min-height: 87%;
                height: unset;

                @include rwd($xxl - 1px) {
                    min-height: 83%;
                }

                h1 {
                    margin-bottom: 75px;
                }

                .react-datepicker__input-container {
                    input {
                        @include sprfs('bd4-n');
                    }
                }

                .react-datepicker-time__header {
                    color: transparent;
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '時間';
                        color: black;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                .inner {
                    width: 472px;
                    text-align: left;

                    .form-control {
                        @include sprfs('bd4-n');
                    }

                    h6 {
                        display: inline-block;
                        margin-bottom: 7px;
                        color: $dark-grey;
                        position: relative;

                        &::after {
                            content: '＊';
                            position: absolute;
                            color: $red;
                            right: -15px;
                            top: -2px;
                            transform: scale(0.7);
                        }

                        &.hint {
                            &::before {
                                content: '請輸入大於零的數字';
                                color: $red;
                                position: absolute;
                                left: 71px;
                                top: 50%;
                                width: 200px;
                                transform: scale(0.95) translateY(-50%);
                            }
                        }
                    }

                    p {
                        border: 1px solid $light-grey;
                        cursor: pointer;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        padding: 15px 20px;
                    }

                    h5 {
                        color: $black5;
                        margin: 16px 0 30px;
                    }

                    input {
                        padding: 15px 20px;
                        width: 100%;
                        border: 1px solid $light-grey;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        color: $black;
                        position: relative;

                        &::placeholder {
                            color: $black3;
                        }
                    }

                    input[type='datetime-local']::-webkit-calendar-picker-indicator {
                        position: absolute;
                        cursor: pointer;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url('../../public/images/marketBackstage/calendar.svg');
                        right: 0;
                        height: 22px;
                        width: 22px;
                        padding: 12px 10px 12px 100%;
                    }

                    > button {
                        background: $pale;
                        width: 100%;
                        height: 41px;
                        color: $white;
                        border-radius: 6px;
                        margin-top: 30px;
                        transition: 0.2s ease-out;

                        &:hover {
                            background: $pale6;
                        }

                        &.act {
                            cursor: not-allowed;
                            background: $black2;

                            &:hover {
                                background: $black2;
                            }
                        }
                    }
                }
            }

            // 3.項目資訊填寫
            .base-info {
                padding: 66px 0;
                background: $white;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;
                width: 100%;
                min-height: 87%;
                height: unset;

                h1 {
                    margin-bottom: 75px;
                }

                .inner {
                    width: 472px;
                    text-align: left;

                    .price-box {
                        padding: 6px 0;
                        display: flex;
                        align-items: center;
                        margin-bottom: 7px;
                        justify-content: flex-start;

                        button {
                            height: 41px;
                            color: $white;
                            margin-right: 10px;
                            border-radius: 6px;
                            width: 114px;
                            background: $white;
                            color: $pale;
                            border: 1px solid $pale;
                            transition: 0.2s ease-out;

                            &:hover {
                                background: rgba($pale, 0.6);
                                color: $white;
                                border: 1px solid rgba($pale, 0.6);
                            }

                            &.act {
                                background: $pale;
                                color: $white;
                            }
                        }

                        span {
                            color: $red;
                        }
                    }

                    h6 {
                        margin-bottom: 7px;
                        color: $dark-grey;
                        position: relative;

                        &:nth-of-type(4) {
                            display: inline-block;

                            &::after {
                                content: '＊';
                                position: absolute;
                                color: $red;
                                right: -15px;
                                top: -2px;
                                transform: scale(0.7);
                            }
                        }

                        &.hint {
                            &::before {
                                content: '請輸入大於零的數字或留空';
                                color: $red;
                                position: absolute;
                                left: 65px;
                                top: 50%;
                                width: 200px;
                                transform: scale(0.95) translateY(-50%);
                            }
                        }

                        &.hintlink {
                            &::before {
                                content: '請輸入正確Twitter網址';
                                color: $red;
                                position: absolute;
                                left: 65px;
                                top: 50%;
                                width: 200px;
                                transform: scale(0.95) translateY(-50%);
                            }
                        }

                        &.hintweb {
                            span {
                                margin-left: 7px;
                                color: $red;
                                display: inline-block;
                                transform: scale(0.95);
                            }
                        }
                    }

                    p {
                        border: 1px solid $light-grey;
                        cursor: pointer;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        padding: 15px 20px;
                        position: relative;

                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                        }
                    }

                    input {
                        position: relative;
                    }
                    input[type='date']::-webkit-calendar-picker-indicator {
                        position: absolute;
                        cursor: pointer;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url('../../public/images/marketBackstage/calendar.svg');
                        right: 0;
                        height: 22px;
                        width: 22px;
                        padding: 12px 10px 12px 100%;
                    }

                    input[type='date'] {
                        color: transparent !important;
                        &::before {
                            position: absolute;
                            color: $black3;
                            content: attr(placeholder) !important;
                            margin-right: 0.5em;
                            width: 100%;
                            z-index: 1;
                            background: white;
                        }

                        &.act {
                            color: unset !important;
                            &::before {
                                width: 0;
                                content: '' !important;
                            }
                        }
                    }

                    h5 {
                        color: $black5;
                        margin: 16px 0 30px;
                    }

                    input {
                        padding: 15px 20px;
                        width: 100%;
                        // cursor: pointer;
                        border: 1px solid $light-grey;
                        border-radius: 4px;
                        margin-bottom: 30px;
                        color: $black;

                        &::placeholder {
                            color: $black3;
                        }
                    }

                    > button {
                        background: $pale;
                        width: 100%;
                        height: 41px;
                        color: $white;
                        border-radius: 6px;

                        &:hover {
                            background: $pale6;
                        }

                        &.act {
                            cursor: not-allowed;
                            background: $black2;

                            &:hover {
                                background: $black2;
                            }
                        }
                    }
                }
            }

            // 4.項目基本資料
            .item-info {
                text-align: center;
                padding: 66px 0;
                background: $white;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;
                width: 100%;
                min-height: 87%;
                height: unset;

                h1 {
                    margin-bottom: 32px;
                }

                .inner {
                    width: 740px;
                    margin: 0 auto;
                    text-align: left;

                    .info-box {
                        height: 174px;
                        padding: 26px 30px 32px;
                        border-radius: 22px;
                        background: $white1;
                        margin-bottom: 32px;

                        // 項目頭像
                        > .img-box {
                            @include whset(131px, 131px);
                            border-radius: 50%;
                            border: 9px solid $white;
                            margin-right: 18px;
                            flex-shrink: 0;
                            overflow: hidden;
                            background: $white;

                            img {
                                left: 0;
                                object-fit: cover;
                                object-position: center;
                            }
                        }

                        .content {
                            margin-right: 45px;
                            text-align: left;
                            flex-shrink: 0;

                            p {
                                > img {
                                    margin-right: 4px;
                                    width: 16px;
                                }

                                a {
                                    margin-right: 9px;
                                    position: relative;
                                    bottom: 2px;

                                    img {
                                        width: 23px;
                                    }
                                }
                            }

                            p:first-of-type {
                                margin-bottom: 27px;
                            }

                            &:last-of-type {
                                margin: 0;
                            }
                        }
                    }

                    h6 {
                        // width: 100%;
                        text-align: left;
                        margin-bottom: 7px;
                        color: $dark-grey;
                        position: relative;
                        display: inline-block;

                        &::after {
                            content: '＊';
                            position: absolute;
                            color: $red;
                            right: -15px;
                            top: -2px;
                            transform: scale(0.7);
                        }
                    }

                    input {
                        width: 100%;
                        padding: 15px 18px;
                        margin-bottom: 32px;
                        border-radius: 4px;
                        border: 1px solid $light-grey;

                        &::placeholder {
                            color: $black3;
                        }
                    }

                    // 領獎方式
                    .accept-box {
                        margin-bottom: 32px;

                        button {
                            width: 230px;
                            color: $pale;
                            border: 1px solid $pale;
                            @include sprfs('btn2');
                            flex-grow: 1;
                            height: 40px;
                            transition: 0.2s ease-out;
                            border-radius: 6px;

                            &.act {
                                background: $pale;
                                color: $white;
                            }

                            &:nth-of-type(2) {
                                margin: 0 25px;
                            }
                        }
                    }

                    // 主視覺
                    .upload-main {
                        margin: 20px auto 32px;

                        img {
                            width: 205px;
                            height: 205px;
                            object-fit: cover;
                            object-position: center;
                            margin-right: 57px;
                        }

                        .right {
                            text-align: left;

                            label {
                                transition: 0.2s ease-out;
                                overflow: hidden;
                                cursor: pointer;
                                display: flex;
                                position: relative;
                                align-items: center;
                                justify-content: center;
                                width: 185px;
                                height: 40px;
                                border-radius: 6px;
                                background: $black9;
                                color: $white;
                                margin-bottom: 20px;

                                &:hover {
                                    background: $pale;
                                }

                                input {
                                    opacity: 0;
                                    z-index: -1;
                                    position: absolute;
                                }
                            }

                            p {
                                color: $silver;
                            }
                        }
                    }

                    // 橫幅圖像
                    .upload-sub {
                        margin: 20px auto 32px;
                        text-align: left;

                        img {
                            width: 100%;
                            height: 247px;
                            object-fit: cover;
                            object-position: center;
                        }

                        .right {
                            label {
                                transition: 0.2s ease-out;
                                overflow: hidden;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                width: 185px;
                                height: 40px;
                                border-radius: 6px;
                                background: $black9;
                                color: $white;
                                margin: 20px 0;

                                &:hover {
                                    background: $pale;
                                }

                                input {
                                    z-index: -1;
                                    opacity: 0;
                                    position: absolute;
                                }
                            }

                            p {
                                color: $silver;
                            }
                        }
                    }

                    input[type='datetime-local'] {
                        position: relative;
                    }

                    input[type='datetime-local']::-webkit-calendar-picker-indicator {
                        position: absolute;
                        cursor: pointer;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url('../../public/images/marketBackstage/calendar.svg');
                        right: 0;
                        height: 22px;
                        width: 22px;
                        padding: 12px 10px 12px 100%;
                    }

                    > button {
                        background: $pale;
                        width: 100%;
                        height: 41px;
                        color: $white;
                        border-radius: 6px;
                        margin-top: 30px;
                        transition: 0.2s ease-out;

                        &:hover {
                            background: $pale6;
                        }

                        &.act {
                            cursor: not-allowed;
                            background: $black2;

                            &:hover {
                                background: $black2;
                            }
                        }
                    }

                    > p {
                        text-align: left;
                        color: $silver;
                    }

                    textarea {
                        height: 304px;
                        resize: none;
                        border: 1px solid $light-grey;
                        width: 100%;
                        border-radius: 4px;
                        margin-top: 23px;
                        padding: 15px 20px;
                    }
                }

                .bts {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;

                    button {
                        width: 306px;
                        height: 41px;
                        transition: 0.2s ease-out;
                        border-radius: 6px;

                        &:first-of-type {
                            color: $black9;
                            border: 1px solid rgba($black2, 0.4);

                            &:hover {
                                color: $white;
                                background: $pale;
                            }
                        }

                        &:last-of-type {
                            color: $white;
                            background: $pale;

                            &.act {
                                cursor: not-allowed;
                                background: $black2;
                            }

                            &:hover {
                                background: $pale5;
                            }
                        }
                    }
                }
            }

            // 5.確認詳情
            .confirm-wrapper {
                padding: 66px 0;
                background: $white;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;
                width: 100%;
                min-height: 87%;
                height: unset;

                h1 {
                    text-align: center;
                    margin-bottom: 32px;
                }

                .info-block {
                    width: 743px;
                    margin: 0 auto;

                    // 項目視覺資訊
                    .main-info {
                        height: 320px;
                        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                        border-radius: 12px;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 32px;

                        // 背景圖
                        .bg-box {
                            height: 132px;
                            width: 100%;

                            img {
                                height: 100%;
                                width: 100%;
                                object-position: center;
                                object-fit: cover;
                            }
                        }

                        // 主視覺圖
                        .vision-box {
                            position: absolute;
                            top: 72px;
                            left: 25px;

                            .img-box {
                                overflow: hidden;
                                border-radius: 12px;
                                background: $white;
                                @include whset(120px, 120px);
                                border: 5px solid $white;

                                img {
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }

                        // 發行資訊
                        .item-text {
                            margin-left: 33px;
                            margin-top: 90px;

                            p {
                                text-align: left;
                                flex-basis: 33%;

                                &.first {
                                    margin-bottom: 18px;
                                }

                                img {
                                    position: relative;
                                    bottom: 2px;
                                }

                                &.link-box {
                                    a {
                                        margin-right: 9px;
                                    }

                                    img {
                                        width: 23px;
                                        transition: 0.2s ease-out;
                                        filter: brightness(0);

                                        &:hover {
                                            filter: brightness(100%);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 領獎方式
                    .accept-box {
                        padding: 0 27px 0 23px;
                        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                        border-radius: 12px;
                        margin-bottom: 32px;
                        margin-top: 32px;
                        height: 62px;

                        h6 {
                            margin-right: 25px;
                        }

                        p {
                            border: 1px solid $black2;
                            color: $black9;
                            padding: 5px 8px;
                            border-radius: 6px;
                        }
                    }

                    // 兌換獎項
                    .exchange-box {
                        padding: 16px 27px 16px 23px;
                        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                        border-radius: 12px;

                        .left {
                            .img-box {
                                @include whset(30px, 30px);
                                margin-right: 18px;
                            }

                            h4 {
                                color: $black9;
                            }
                        }

                        p {
                            color: $black9;
                        }
                    }

                    .info-content {
                        margin-top: 32px;
                        color: $black9;
                    }

                    .btn-box {
                        margin-top: 32px;
                        margin-bottom: 30px;

                        button {
                            width: 306px;
                            height: 41px;
                            transition: 0.2s ease-out;
                            border-radius: 6px;

                            &.back {
                                background: $white;
                                border: 1px solid $pale;
                                color: $pale;

                                &:hover {
                                    color: $white;
                                    background: $pale;
                                }
                            }

                            &.save {
                                background: $blue5;
                                color: $white;
                                transition: 0.2s ease-out;

                                &:hover {
                                    background: $blue7;
                                }
                            }
                        }
                    }
                }
            }

            // 6.設定登記抽獎條件
            .set-box {
                padding: 66px 0;
                background: $white;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;
                width: 100%;
                min-height: 87%;
                height: unset;

                > h1 {
                    text-align: center;
                    margin-bottom: 58px;
                }

                .inner {
                    width: 743px;
                    margin: 0 auto;

                    > div {
                        transition: 0.2s ease-out;
                        border: 1px solid rgba(199, 199, 199, 0.4);
                        border-radius: 12px;
                        padding: 40px 32px;
                        margin-bottom: 58px;
                        &:nth-of-type(3),
                        &:nth-of-type(4),
                        &:nth-of-type(5),
                        &:nth-of-type(6),
                        &:nth-of-type(7) {
                            button {
                                width: 327px;
                            }
                        }

                        &.red {
                            border: 2px solid rgba($red, 0.6);
                        }

                        .more-box {
                            transition: 0.2s ease-out;

                            .top,
                            .bottom,
                            .raffle {
                                position: relative;
                                opacity: 0;
                                z-index: -1;
                                max-height: 0;
                                p > span {
                                    color: $red;
                                    margin-left: 10px;
                                    display: inline-block;
                                    transform: scale(0.95);
                                }
                            }
                        }

                        h2 {
                            color: $dark-grey;
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 16px;
                                width: 40px;
                                height: 40px;
                                object-fit: contain;
                                object-position: center;
                            }
                        }

                        > p {
                            margin: 16px 0 30px;
                            color: $black5;
                        }

                        > div {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            button {
                                width: 211px;
                                border: 1px solid $pale;
                                border-radius: 6px;
                                color: $pale;
                                height: 41px;
                                transition: 0.2s ease-out;

                                &:hover {
                                    background: rgba($pale, 0.6);
                                    color: $white;
                                }

                                &.act {
                                    background: $pale;
                                    color: $white;
                                }
                            }
                        }
                    }

                    .btns {
                        margin-top: 32px;
                        border: none;
                        padding: 0;
                        margin-bottom: 30px;

                        button {
                            width: 306px;
                            height: 41px;
                            transition: 0.2s ease-out;
                            border-radius: 6px;

                            &.back {
                                background: $white;
                                border: 1px solid $pale;
                                color: $pale;

                                &:hover {
                                    color: $white;
                                    background: $pale;
                                }
                            }

                            &.save {
                                background: $blue5;
                                color: $white;
                                transition: 0.2s ease-out;

                                &:hover {
                                    background: $blue7;
                                }
                            }

                            &.act {
                                cursor: not-allowed;
                                background: $black2;
                            }
                        }
                    }
                }

                // discord
                .dc-box {
                    border: 1px solid black;
                    transition: 0.2s ease-out;

                    &.act {
                        .more-box {
                            .top {
                                opacity: 1;
                                z-index: 1;
                                max-height: unset;
                                padding-top: 30px;
                            }
                        }
                    }

                    &.act2 {
                        .more-box {
                            .top,
                            .bottom,
                            .raffle {
                                opacity: 1;
                                z-index: 1;
                                max-height: unset;
                                padding-top: 30px;
                            }

                            .bottom {
                                margin-top: 30px;
                                border-top: 1px dashed rgba($black2, 0.4);
                            }

                            // 抽獎券加成
                            .raffle {
                                margin-top: 30px;
                                padding-top: 30px;
                                border-top: 1px dashed rgba($black2, 0.4);
                                h5 {
                                    color: $dark-grey;
                                    margin-bottom: 10px;
                                }
                                h6 {
                                    color: $black3;
                                    margin-bottom: 30px;
                                }
                                > div {
                                    padding: 27px 14px;
                                    border-radius: 6px;
                                    border: 1px solid rgba($black2, 0.4);
                                }
                            }
                        }
                    }

                    .more-box {
                        transition: 0.2s ease-out;
                        display: block;
                        .top,
                        .bottom,
                        .raffle {
                            padding-top: 0;
                            transition: 0.2s ease-out;
                            position: relative;
                            opacity: 0;
                            z-index: -1;
                            max-height: 0;
                            h5 {
                                margin-bottom: 30px;
                                color: $dark-grey;
                            }

                            p {
                                margin-bottom: 10px;
                                color: $dark-grey;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 10px;
                                    cursor: pointer;
                                }
                            }

                            input {
                                margin-bottom: 30px;
                                width: 100%;
                                border: 1px solid rgba($black2, 0.4);
                                border-radius: 4px;
                                padding: 15px 20px;
                                color: $black;
                                @include sprfs('bd4-n');

                                &:last-of-type {
                                    margin: 0;
                                }

                                &::placeholder {
                                    color: $black3;
                                }
                            }
                        }
                        .raffle {
                            > div {
                                margin-bottom: 30px;
                                .first {
                                    margin-bottom: 30px;
                                    div {
                                        width: 49%;
                                    }
                                }
                            }
                            // 新增role按鈕
                            button {
                                background: $pale;
                                color: $white;
                            }
                        }
                    }
                }

                // twitter
                .twitter-box {
                    &.act {
                        .more-box {
                            .top {
                                padding-top: 30px;
                                opacity: 1 !important;
                                z-index: 1 !important;
                                max-height: unset !important;
                            }
                        }
                    }

                    &.act2 {
                        .bottom,
                        .top {
                            padding-top: 30px;
                            opacity: 1 !important;
                            z-index: 1 !important;
                            max-height: unset !important;
                        }
                    }

                    .more-box {
                        .top,
                        .bottom {
                            p {
                                margin-bottom: 10px;
                                position: relative;

                                &.hint {
                                    &::after {
                                        position: absolute;
                                        bottom: -81px;
                                        left: 0;
                                        content: '請輸入正確網址';
                                        color: $red;
                                        transform: scale(0.95);
                                    }
                                }
                            }
                        }

                        .top {
                            > div {
                                width: 100%;
                                border-radius: 4px;
                                border: 1px solid rgba($black2, 0.4);
                                overflow: hidden;
                                align-items: stretch !important;

                                span {
                                    cursor: pointer;
                                    border: 1px solid rgba($black2, 0.4);
                                    color: $black8;
                                    height: 27px;
                                    padding: 4px 30px 5px 13px;
                                    border-radius: 4px;
                                    position: relative;
                                    margin: 0 0 2px 10px;

                                    &::after {
                                        content: '྾';
                                        font-weight: 500;
                                        font-size: 12px;
                                        position: absolute;
                                        right: 10px;
                                        top: 4px;
                                    }
                                }

                                input {
                                    padding-left: 15px;
                                    color: $black;
                                    flex-grow: 1;
                                    padding-right: 15px;

                                    &.act {
                                        width: 0;
                                    }

                                    &::placeholder {
                                        color: $black3;
                                    }
                                }

                                > div {
                                    flex-wrap: wrap;
                                    padding: 7px 0;
                                    max-width: 470px;
                                }

                                .img-box {
                                    padding: 0;
                                    background: rgba(199, 199, 199, 0.4);
                                    display: flex;
                                    align-items: center;
                                    width: 50px;
                                    flex-shrink: 0;

                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .bottom {
                            display: flex;

                            .left {
                                width: 270px;
                                margin-right: 12px;

                                input {
                                    padding: 0 20px;
                                    height: 46px;
                                    width: 100%;
                                    color: $black;
                                    border: 1px solid rgba($black2, 0.4);
                                    border-radius: 4px;
                                    @include sprfs('bd4-n');

                                    &::placeholder {
                                        color: $black3;
                                    }
                                }
                            }

                            .right {
                                flex-grow: 1;

                                button {
                                    width: 127px;
                                    height: 46px;
                                    transition: 0.2s ease-out;
                                    border: 1px solid $pale;
                                    color: $pale;
                                    border-radius: 6px;

                                    &.act {
                                        background: $pale;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }

                // instagram
                .instagram-box {
                    &.act {
                        .more-box {
                            .top {
                                padding-top: 30px;
                                opacity: 1 !important;
                                z-index: 1 !important;
                                max-height: unset !important;
                            }
                        }
                    }

                    &.act2 {
                        .bottom,
                        .top {
                            padding-top: 30px;
                            opacity: 1 !important;
                            z-index: 1 !important;
                            max-height: unset !important;
                        }
                    }

                    .more-box {
                        .top,
                        .bottom {
                            p {
                                margin-bottom: 10px;
                            }
                        }

                        .top {
                            > div {
                                width: 100%;
                                border-radius: 4px;
                                height: 46px;
                                border: 1px solid rgba($black2, 0.4);

                                span {
                                    margin-left: 10px;
                                    cursor: pointer;
                                    border: 1px solid rgba($black2, 0.4);
                                    color: $black8;
                                    height: 27px;
                                    padding: 4px 30px 5px 13px;
                                    border-radius: 4px;
                                    position: relative;

                                    &::after {
                                        content: '྾';
                                        font-weight: 500;
                                        font-size: 12px;
                                        position: absolute;
                                        right: 10px;
                                        top: 4px;
                                    }
                                }

                                input {
                                    padding-left: 15px;
                                    color: $black;
                                    flex-grow: 1;
                                    padding-right: 15px;

                                    &::placeholder {
                                        color: $black3;
                                    }
                                }

                                > div {
                                    max-width: 320px;
                                    @include noScrollbar;
                                }
                            }
                        }

                        .bottom {
                            display: flex;

                            .left {
                                width: 270px;
                                margin-right: 12px;

                                input {
                                    padding: 0 20px;
                                    height: 46px;
                                    width: 100%;
                                    color: $black;
                                    border: 1px solid rgba($black2, 0.4);
                                    border-radius: 4px;
                                    @include sprfs('bd4-n');

                                    &::placeholder {
                                        color: $black3;
                                    }
                                }
                            }

                            .right {
                                flex-grow: 1;

                                button {
                                    width: 127px;
                                    height: 46px;
                                    transition: 0.2s ease-out;
                                    border: 1px solid $pale;
                                    color: $pale;
                                    border-radius: 6px;

                                    &.act {
                                        background: $pale;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }

                // facebook
                .facebook-box {
                    &.act {
                        .more-box {
                            .top {
                                padding-top: 30px;
                                opacity: 1 !important;
                                z-index: 1 !important;
                                max-height: unset !important;
                            }
                        }
                    }

                    &.act2 {
                        .bottom,
                        .top {
                            padding-top: 30px;
                            opacity: 1 !important;
                            z-index: 1 !important;
                            max-height: unset !important;
                        }
                    }

                    .more-box {
                        .top,
                        .bottom {
                            p {
                                margin-bottom: 10px;

                                span {
                                    color: $red;
                                    margin-left: 7px;
                                    display: inline-block;
                                    transform: scale(0.95);
                                }
                            }
                        }

                        .top {
                            > div {
                                width: 100%;
                                border-radius: 4px;
                                height: 46px;
                                border: 1px solid rgba($black2, 0.4);

                                span {
                                    margin-left: 10px;
                                    cursor: pointer;
                                    border: 1px solid rgba($black2, 0.4);
                                    color: $black8;
                                    height: 27px;
                                    padding: 4px 30px 5px 13px;
                                    border-radius: 4px;
                                    position: relative;

                                    &::after {
                                        content: '྾';
                                        font-weight: 500;
                                        font-size: 12px;
                                        position: absolute;
                                        right: 10px;
                                        top: 4px;
                                    }
                                }

                                input {
                                    padding-left: 15px;
                                    color: $black;
                                    flex-grow: 1;
                                    padding-right: 15px;

                                    &::placeholder {
                                        color: $black3;
                                    }
                                }

                                > div {
                                    max-width: 320px;
                                    @include noScrollbar;
                                }
                            }
                        }

                        .bottom {
                            display: flex;

                            .left {
                                width: 270px;
                                margin-right: 12px;

                                input {
                                    padding: 0 20px;
                                    height: 46px;
                                    width: 100%;
                                    color: $black;
                                    border: 1px solid rgba($black2, 0.4);
                                    border-radius: 4px;
                                    @include sprfs('bd4-n');

                                    &::placeholder {
                                        color: $black3;
                                    }
                                }
                            }

                            .right {
                                flex-grow: 1;

                                button {
                                    width: 127px;
                                    height: 46px;
                                    transition: 0.2s ease-out;
                                    border: 1px solid $pale;
                                    color: $pale;
                                    border-radius: 6px;

                                    &.act {
                                        background: $pale;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }

                // 錢包
                .wallet-box {
                    display: block;

                    &.act {
                        .more-box {
                            .top {
                                padding-top: 30px;
                                opacity: 1;
                                z-index: 1;
                                max-height: unset;
                            }
                        }
                    }

                    .more-box {
                        .top {
                            width: 100%;

                            p {
                                color: $dark-grey;
                                margin-bottom: 7px;
                            }

                            input {
                                padding: 15px 20px;
                                @include sprfs('bd4-n');
                                width: 100%;
                                border: 1px solid rgba($black2, 0.4);
                                color: $black;
                                border-radius: 4px;

                                &::placeholder {
                                    color: $black3;
                                }
                            }
                        }
                    }
                }

                // NFT
                .NFT-box {
                    &.act {
                        .more-box {
                            .top {
                                padding-top: 30px;
                                max-height: unset;
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                    }

                    .top {
                        width: 100%;

                        p {
                            color: $dark-grey;
                            margin-bottom: 7px;

                            &.hint {
                                span {
                                    color: $red;
                                    margin-left: 7px;
                                    display: inline-block;
                                    transform: scale(0.95);
                                }
                            }
                        }

                        input {
                            padding: 15px 20px;
                            @include sprfs('bd4-n');
                            width: 100%;
                            border: 1px solid rgba($black2, 0.4);
                            color: $black;
                            border-radius: 4px;
                            margin-bottom: 22px;

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            &::placeholder {
                                color: $black3;
                            }
                        }
                    }
                }

                // 問題
                .question-box {
                    &.act {
                        .more-box {
                            .top {
                                padding-top: 30px;
                                max-height: unset;
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                    }

                    .top {
                        width: 100%;
                        p {
                            color: $dark-grey;
                            margin-bottom: 7px;
                        }

                        input {
                            padding: 15px 20px;
                            @include sprfs('bd4-n');
                            width: 100%;
                            border: 1px solid rgba($black2, 0.4);
                            color: $black;
                            border-radius: 4px;
                            margin-bottom: 22px;

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            &::placeholder {
                                color: $black3;
                            }
                        }
                    }
                }

                // 閱讀文章
                .read-box {
                    &.act {
                        .more-box {
                            .top {
                                padding-top: 30px;
                                max-height: unset;
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                    }
                    .more-box {
                        flex-grow: 1;
                    }
                    .top {
                        p {
                            color: $dark-grey;
                            margin-bottom: 7px;
                        }
                        .article {
                            position: relative;
                            img {
                                position: absolute;
                                top: 15px;
                                z-index: 1;
                                right: 15px;
                                transition: 0.3s linear;
                                &.act {
                                    transform: rotate(180deg);
                                }
                            }
                            input {
                                display: block;
                                padding: 15px 45px 15px 20px;
                                @include sprfs('bd4-n');
                                width: 100%;
                                border: 1px solid rgba($black2, 0.4);
                                color: $black;
                                border-radius: 4px;
                                position: relative;
                                &::placeholder {
                                    color: $black3;
                                }
                            }
                            ul {
                                border: 1px solid rgba($black2, 0.4);
                                border-radius: 4px;
                                background: white;
                                width: 100%;
                                max-height: 0;
                                overflow-y: scroll;
                                transition: 0.3s linear;
                                opacity: 0;
                                position: absolute;
                                top: 48.5px;
                                &.act {
                                    opacity: 1;
                                    max-height: 144px;
                                }
                                li {
                                    cursor: pointer;
                                    @include sprfs('bd4-n');
                                    color: $black3;
                                    padding: 15px 20px;
                                    border-bottom: 1px solid rgba($black2, 0.4);
                                    width: 100%;
                                    transition: 0.2s ease-out;
                                    &:hover {
                                        background-color: rgba($black2, 0.4);
                                    }
                                    &:last-of-type {
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // 7.解說
            .explanation {
                background: $white;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;

                > div {
                    width: 600px;
                    padding-bottom: 60px;
                    margin: 0 auto;

                    h1 {
                        padding-top: 60px;
                        margin-bottom: 55px;
                    }

                    .inner {
                        > p {
                            color: $black5;
                        }

                        h5 {
                            margin: 32px 0 25px;
                        }

                        img {
                            width: 100%;
                            margin: 32px 0;

                            &:nth-of-type(3),
                            &:nth-of-type(2) {
                                width: 350px;
                            }
                        }
                    }
                }
            }

            // 8.確認條件詳情
            .confirm-condition {
                background: $white;
                box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                border-radius: 12px;

                input {
                    background: $white1;
                    cursor: not-allowed;
                }

                .question-box {
                    margin-bottom: 0 !important;
                }

                .info-block {
                    width: 743px;
                    margin: 0 auto;

                    // 項目視覺資訊
                    .main-info {
                        display: block !important;
                        height: 320px;
                        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                        border-radius: 12px;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 32px;

                        // 背景圖
                        .bg-box {
                            height: 132px;
                            width: 100%;

                            img {
                                height: 100%;
                                width: 100%;
                                object-position: center;
                                object-fit: cover;
                            }
                        }

                        // 主視覺圖
                        .vision-box {
                            position: absolute;
                            top: 72px;
                            left: 25px;

                            .img-box {
                                background: $white;
                                overflow: hidden;
                                border-radius: 12px;
                                @include whset(120px, 120px);
                                border: 5px solid $white;

                                img {
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }

                        // 發行資訊
                        .item-text {
                            margin-left: 33px;
                            margin-top: 90px;

                            p {
                                text-align: left;
                                flex-basis: 33%;

                                &.first {
                                    margin-bottom: 18px;
                                }

                                img {
                                    position: relative;
                                    bottom: 2px;
                                }

                                &.link-box {
                                    a {
                                        margin-right: 9px;
                                    }

                                    img {
                                        width: 23px;
                                        transition: 0.2s ease-out;
                                        filter: brightness(0);

                                        &:hover {
                                            filter: brightness(100%);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 領獎方式
                    .accept-box {
                        padding: 0 27px 0 23px;
                        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                        border-radius: 12px;
                        margin-bottom: 32px;
                        margin-top: 32px;
                        height: 62px;

                        h6 {
                            margin-right: 25px;
                        }

                        p {
                            border: 1px solid $black2;
                            color: $black9;
                            padding: 5px 8px;
                            border-radius: 6px;
                        }
                    }

                    // 兌換獎項
                    .exchange-box {
                        padding: 16px 27px 16px 23px;
                        box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
                        border-radius: 12px;

                        .left {
                            .img-box {
                                @include whset(30px, 30px);
                                margin-right: 18px;
                            }

                            h4 {
                                color: $black9;
                            }
                        }

                        p {
                            color: $black9;
                        }
                    }

                    .info-content {
                        margin-top: 32px;
                        color: $black9;
                    }

                    .btn-box {
                        margin-top: 32px;
                        margin-bottom: 30px;

                        button {
                            width: 306px;
                            height: 41px;
                            transition: 0.2s ease-out;
                            border-radius: 6px;

                            &.back {
                                background: $white;
                                border: 1px solid $pale;
                                color: $pale;

                                &:hover {
                                    color: $white;
                                    background: $pale;
                                }
                            }

                            &.save {
                                background: $blue5;
                                color: $white;
                            }
                        }
                    }
                }

                .inner {
                    button {
                        border: none !important;
                        width: 211px;
                        background: $black;
                        color: $white !important;
                        cursor: not-allowed !important;

                        &:hover {
                            color: $white !important;
                            background: $black !important;
                        }
                    }

                    .twitter-box {
                        .more-box {
                            .top > div {
                                background-color: $white1;
                                cursor: not-allowed !important;

                                img {
                                    cursor: not-allowed !important;
                                }

                                span {
                                    background-color: white;
                                    cursor: not-allowed;
                                    padding-right: 13px;

                                    &::after {
                                        content: '';
                                    }
                                }
                            }
                        }
                    }
                }

                .alert {
                    margin: 32px 0;
                    color: rgba($red, 0.8);
                }

                .btns {
                    position: relative;

                    button {
                        transition: 0.2s ease-out;
                        cursor: pointer !important;
                    }

                    .back {
                        width: 148px !important;
                        border: 1px solid rgba($black2, 0.4) !important;
                        color: $black9 !important;
                        position: absolute;
                        left: 0;

                        &:hover {
                            color: $white !important;
                            background: $pale !important;
                        }
                    }

                    .draft {
                        width: 212px !important;
                        color: $pale !important;
                        background: $white !important;
                        border: 1px solid $pale !important;
                        margin-right: 14px;

                        &:hover {
                            color: $white !important;
                            background: $pale !important;
                        }
                    }

                    .save {
                        width: 212px !important;
                        color: $white;
                        border: 1px solid $blue5;

                        &:hover {
                            background: $blue7 !important;
                        }
                    }
                }
            }

            // 9.確認所有
            .all {
                h1 {
                    margin-bottom: 32px;
                }

                .btns-box {
                    border: none !important;
                    margin-top: 74px;
                    padding: 0 !important;

                    button {
                        height: 41px;
                        border-radius: 6px;
                        cursor: pointer !important;
                        transition: 0.2s ease-out;
                    }

                    .back {
                        width: 208px !important;
                        background: $white !important;
                        border: 1px solid rgba($black2, 0.4) !important;
                        color: $black9 !important;
                        margin-right: 50px;

                        &:hover {
                            color: $white !important;
                            background: $pale !important;
                        }
                    }

                    .delete {
                        background: $red;
                        color: $white;
                        width: 129px !important;
                        flex-shrink: 0;

                        &:hover {
                            background: rgb(194, 5, 5) !important;
                        }
                    }

                    .edit {
                        width: 129px !important;
                        color: $pale !important;
                        background: $white !important;
                        border: 1px solid $pale !important;
                        margin: 0 6px !important;

                        &:hover {
                            color: $white !important;
                            background: $pale !important;
                        }
                    }

                    .post {
                        background: $blue5;
                        color: $white;

                        &:hover {
                            background: $blue7 !important;
                        }
                    }

                    .fix {
                        background: $black8;
                        color: $white;

                        &:hover {
                            background: $black !important;
                        }
                    }
                }

                .inner {
                    .info-block {
                        border: none;
                        padding: 0;
                    }

                    > div {
                        margin-bottom: 32px;
                    }
                }
            }
        }

        // 防呆流程
        #procedure {
            width: 100%;
            margin-bottom: 11px;
            background: $white;
            height: 104px;
            padding: 36px 80px 25px;
            box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
            border-radius: 12px;

            div {
                flex-grow: 1;
                position: relative;
                padding-top: 26px;
                border-top: 1px solid $black2;

                &::after {
                    content: '';
                    position: absolute;
                    top: -6px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: $black2;
                }

                p {
                    color: rgba($black4, 0.8);
                }

                &:last-of-type {
                    border: none;
                }
            }

            &.p1p {
                .p1 {
                    &::after {
                        background: $pale;
                        box-shadow: 0px 0px 10px #46c5d7;
                    }

                    p {
                        color: rgba($black, 0.8);
                    }
                }
            }

            &.p2p {
                .p1 {
                    border-top-color: $pale;
                }

                .p1,
                .p2 {
                    &::after {
                        background: $pale;
                        box-shadow: 0px 0px 10px #46c5d7;
                    }

                    p {
                        color: rgba($black, 0.8);
                    }
                }
            }

            &.p3p,
            &.p4p {
                .p1,
                .p2 {
                    border-top-color: $pale;
                }

                .p1,
                .p2,
                .p3 {
                    &::after {
                        background: $pale;
                        box-shadow: 0px 0px 10px #46c5d7;
                    }

                    p {
                        color: rgba($black, 0.8);
                    }
                }
            }

            &.p5p {
                .p1,
                .p2,
                .p3 {
                    border-top-color: $pale;
                }

                .p1,
                .p2,
                .p3,
                .p4 {
                    &::after {
                        background: $pale;
                        box-shadow: 0px 0px 10px #46c5d7;
                    }

                    p {
                        color: rgba($black, 0.8);
                    }
                }
            }

            &.p6p {
                .p1,
                .p2,
                .p3,
                .p4 {
                    border-top-color: $pale;
                }

                .p1,
                .p2,
                .p3,
                .p4,
                .p5 {
                    &::after {
                        background: $pale;
                        box-shadow: 0px 0px 10px #46c5d7;
                    }

                    p {
                        color: rgba($black, 0.8);
                    }
                }
            }
        }
    }
}
