@import "./common/variables";
@import "./common/varbreakpoints";
@import "./common/varoptions";
@import "./common/keyframes";
@import "./common/mixins";
@import "./common/functions";
@import "./common/reboot";
@import "./common/utilities";
@import "./common/classbuild";
@import "./common/grid";
@import "./common/containers";

#root{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @include rwd($xxl - 1px){
        margin: 0 auto;
        width: 100%;
        min-width: 1440px;
    }
}
